*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.table-section .table td{
    padding-right: 1px !important;
}
.table-section .table th{
    padding: 5px !important;
}
.table-section {
    padding-top: 20px;
    background: #fff3e9;
}
.table-section .table thead tr {
    background: #fc5400;
    color: white;
    font-size: 15px;
    /* border: 1px solid black; */
    letter-spacing: 1px;
}
table.table {
    width: 100%;
    overflow-x: scroll;
}
.table-section .table tbody tr{
    font-size: 14px;
    border: 1px solid black;
 

}

tbody tr td:nth-child(1) {
    background: #ffe7d5;
}
tbody tr td:nth-child(2) {
    background: #ffe7d5;
}
tbody tr td:nth-child(3) {
    background: #ffe7d5;
}
tbody tr td:nth-child(4) {
    background: #b00000;

    color: white;
}
tbody tr td:nth-child(5) {
    /* background: #ffe7d5; */
    /* color: white; */
}
.table>:not(caption)>*>* {
   
    border-bottom-width: 0px !important;
   
}
.slide-div .slick-slider.slick-slider {
    background: #ffcca6 !important;
}

tbody tr td:last-child{
    background-color: #ffe7d5;
}
tbody tr .h-green{
    background-color: #d5ffd5;
}
tbody tr .m-green{
    background-color: #aeffae;
}
tbody tr .d-green{
    background-color: #d5ffd5 ;
}
tbody tr .w-green{
    background-color: #f7fff7 ;
}

.spread {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spread p strong {
    color: black;
    font-size: 19px;
}


.spread ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin-left: 1rem;
    background: linear-gradient(45deg, #dedede, #4cff4c);
    padding: 0px 10px;
    border: 1px solid black;
    
}

.spread ul li {
  margin-left: 37px;
}
.spread ul li:nth-child(1) {
    margin-left: 5px;
  }


  table.table tr td sub {
    position: absolute;
    bottom: 8px;
    right: 1px;
}
table.table tr td {
    position: relative;
    padding: 10px;
}

ul.star-list li i {
    color: #dba723;
    font-size: 18px;
}
ul.star-list {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
}