:root {
    --white: #ffffff;
    --black: #000000;
    --orange: #fc5400;
    --dark-blue: #001adb;
    --lightblue: #e2f7ff;
    --black2:#101113;
    }
    
    body {
        font-family: "lato", sans-serif;
    overflow-x: hidden;
   
    }
    .header-part {
        background: var(--black2);
    }
    .top-left h2 {
        color: #9c9898;
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 300;
        letter-spacing: 1px;
    }
    .header-part {
        background: var(--orange);
        padding: 10px 0px;
        text-align: center;
    }
    .header-part p {
        margin-bottom: 0px;
        color: #fff;
        letter-spacing: 2px;
        font-family: lato;
    }
    .main-header {
        background: #0f2544;
        padding: 25px 0px;
        border-top: 1px solid #0f2544;
    }
    .search-box input[type="search"] {
        color: #fff;
        background-color: #2B2E34;
        border: none;
        border-radius: 25px;
        padding: 4px 30px;
        font-size: 12px;
        width: 350px;
        height: 34px;
    }
    .search-box {
        position: relative;
    }
    .search-box > img {
        position: absolute;
        right: 11px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
    }
    .top-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .main-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .logo-left img {
        width: 125px;
    }
    .menu-center ul {
        display: flex;
        padding-left: 0px;
        margin-bottom: 0px;
        list-style: none;
    }
    
    .sign-right {
        display: flex;
        align-items: center;
    }
    .menu-center ul li a {
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        font-family: 'Lato';
        letter-spacing: 2px;
    }
    .sign-right img {
        width: 22px;
    }
    .account1 {
        margin: 0px 20px;
    }
    
    .menu-center > ul > li {
        padding: 0px 20px;
    }
    .login-part a {
        background: var(--orange);
        padding: 10px 30px;
        border-radius: 4px;
        color: #fff;
        text-decoration: none;
        font-size: 15px;
    }
    .Signup {
        margin-left: 0px;
       
    }
    .menu-center ul li a:hover {
        color: var(--orange);
    }
    .cart-img img {
        width: 25px;
    }
    .Signup a {
        background: var(--dark-blue);
        padding: 10px 30px;
        border-radius: 4px;
        color: #fff;
        text-decoration: none;
        font-size: 17px;
    }
    .login-part {
        margin-right: 10px;
    }
    .cart-img {
        padding-right: 25px;
    }
    .card-header {
        padding: 15px;
        background-color: var(--dark-blue);
        border-radius: 15px 15px 0 0;
        align-items: start;
    }
    .card-header h3 {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 1px;
        color: var(--white)
    }
    .slider-content-container {
        padding: 35px 35px 49px;
        background-color: #15171a;
        border-radius: 0 0 15px 15px;
    }
    .headshot-container {
        text-transform: uppercase;
        margin-bottom: 35px;
    }
    .slider-content-container .full-description {
        font-size: 14px;
        margin-bottom: 35px;
        min-height: 190px;
    }
    .slider-content-container .cart-buttons-container {
        border-top: 1px solid #686F78;
        border-bottom: 1px solid #686F78;
        grid-template-columns: 1fr 1fr;
        padding: 20px 0;
    }
    .slider-content-container .user-short-description h2 {
        font-size: 34px;
        font-weight: 300;
        color: var(--orange);
        margin-bottom: 15px;
        text-decoration: none;
    }
    
    .slider-content-container .user-short-description h6, .slider-content-container .user-short-description h5 {
        font-size: 16px;
        font-weight: 700;
        color:#bfc4e4;
        text-decoration: none;
    }
    .user-short-description a {
        text-decoration: none;}
        .slider-content-container .cart-buttons-container .primary {
            background-color: var(--dark-blue);
        }
        
        .slider-content-container .cart-buttons-container .btn {
            color: #fff;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
        }   
        .slider-content-container .cart-buttons-container .secondary {
            background-color: #F48120;
        }
        .full-description p {
            color: #fff;
        }
        .headshot-image {
            width: 190px !important;
            height: 190px !important;
            border: 6px solid #32383F;
            border-radius: 200px;
            margin-right: 15px;
        }
        .tabs-content-container {
            padding-top: 10px;
        }
        .tabs-content-container ul#pills-tab {
    
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: 10px !important;
            padding-bottom: 10px;
            padding-top: 10px;
        }
        .tabs-container .tabs-nav-wrapper:after {
            content: '';
            width: 45px;
            height: 100%;
            position: absolute;
            border-radius: 0;
            background: linear-gradient(268deg, #0a0b0c, #0a0b0c96, transparent);
            z-index: 2;
            top: 0;
            right: -2px;
        }
        .tabs-container .nav-link, .what-pros-say-section .nav-link, .sticky a.nav-link {
            color: #fff;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 6px;
            font-weight: bold;
            text-transform: uppercase;
            border: 2px solid #686F78;
            margin-right: 0.75em;
            padding: 10px 30px;
        }
        .tabs-container .nav-link.active, .what-pros-say-section .nav-link.active {
        background-color: #f48120;
        border: 2px solid #f48120;
    }
    .tab-content-heading.d-grid h3:nth-child(2) {
        padding-left: 14px;
    }
    .video-wrapper::before {
        content: '';
        width: 35px;
        height: 35px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAnCAYAAACMo1E1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIbSURBVHgB1ZiPVcIwEId/5TkAI9QJLBNQJpANeE6gToBOABvABuAEhQmACdoR2OC8I6mvRpDmT4N+7x0t0LQfyTU5msABIurzZszxwJFzyPu0cUilY8+xlW2SJBW6hKVyjoLcWEl7hMZTyqTkyOALn6TPMadumJFKDyexVP/KLinlOjZescTsBSOL/SqYGGKSAzt8nxZiUXEMeMo51h/0jAPecBsx6OtOz37DvZZZDMOCY0rdkJ+TKy1OMNFtJD8XFJbCFMvt2iu5Rvshhb2JTr1X59wzPOAk3nLc8+4TVGL7onKP1NDYMrl0Vn2+GfnTl57zX+caSPXB8cq70pNLuPMockN0gJaUYXYd6ix4z5mw4FLno/RmZdE0F7kUEWDBOW9GaD/UfZAbE3igb5rdtYv0cAN0Pg6gyviL3ESOdGWNK/kuchUiQaqyXvCuiOVXDq/u+OWICFK8eYFaidqW5ie5DTqcTkitk9JbKezYy7Ae0AGkSjAZPokU9mxEbo2A6LySOU0q6hzuHHq6LN4gACwleVXCs8ph1l9PCMi/npP2JYVjaP7qwqJxsxIuKCxl7XTX8HtH+xyRZJfFfIrwjM9+SmGKRB9mTZ//879VfzFCxCVNU8l1m2IXob/6rCSyYGkt1hCU2b6rm8T9+ZwhmVG4XizInGRDQOqf/YrcKGylEjhAKk/qJ+lSbqX4+TS9XrOlFP9odScafAI5lYN1ls1p0gAAAABJRU5ErkJggg==) no-repeat 0 0 / cover;
        position: absolute;
        z-index: 2;
        top: 45%;
        left: 44%;
        transform: translateY(-50%);
    }
    .video-part11 img {
        width: 100%;
    }
    .modal-dialog.dialog1 {
        max-width: 100% !important;
        margin-right: auto !important;
        margin-left: auto !important;}
        .video-heading {
            text-align: center;
            margin-bottom: 50px;
            margin-top: 50px;
        }
    section.hero-section h3, .offers-section h3, .card-header h3, .card-header h2 {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .tab-content-heading.d-grid {
        grid-template-columns: 1fr 2fr 1fr 1fr;
        margin-bottom: 16px;
    }
    .video-heading h2 {
        color: var(--orange);}
        .video-heading p {
            color: #ddd;
        }
        section.video-shows img {
            width: 100%;
        }
    .tab-content-heading h3 {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
        color: var(--orange);
    }
    .tab-content-main.d-grid {
        grid-template-columns: 1fr 4fr;
        align-items: center;
    }
    
    .content-list-grid.d-grid {
        grid-template-columns: 2fr 1fr 1fr;
        padding: 8px 14px;
        border: 1px solid #2B2E34;
        background-color: #101113;
        margin-bottom: 4px;
        border-radius: 12px;
        font-size: 16px;
    }
    .tab-content-main h3.main-content-heading {
        font-size: 18px;
        grid-row: span 3;
        align-self: start;
        color: #F48120;
        padding-top: 0.5rem;
        margin-top: 0 !important;
    }
    .content-list-grid.d-grid .content-title a {
        color: #85b8ff;
        text-decoration: none;
    }
    .content-list-grid.d-grid .content-amount {
        font-weight: bold;
        color:#fff;
    }
    .content-percentage {
        color: #fff;
    }
    .who-is-hot-coming-soon {
        color: #F48120;
    }
    .tabs-content-container .tab-content {
        background-color: #15171A;
        padding: 24px;
        border-radius: 0 0 15px 15px;
    }
    section.slider-tabs {
        padding-top: 100px;
        background: #0f2544;
        padding-bottom: 50px;
    }
    p.video-title {
        text-align: center;
        letter-spacing: 1px;
        font-size: 20px;
        margin-bottom: 20px;
        color: #0f2544;
    }
    
    
    
    .tab {
        float: left;
        border: 1px solid #000;
        background-color: var(--orange);
        width: 7%;
        height: 433px;
        position: sticky;
    }
      /* Style the buttons inside the tab */
      .tab button {
        display: block;
        background-color: inherit;
        color: #fff;
        padding: 15px 16px;
        width: 100%;
        border: none;
        outline: none;
        text-align: left;
        cursor: pointer;
        transition: 0.3s;
        font-size: 15px;
        border-bottom: 1px solid #e9c194;
    }
    section.custumtab {
        margin-top: 0px;
        overflow: hidden;
        padding: 100px 0px;
        background: #ffe7d5;
    }
    ul#myTab {
        margin-left: 40px;
    }
    .aBT {
        overflow: hidden;
        clear: both;
        background: #141313;
        padding: 60px 0px 80px 0px;
        background: url(/public/images/abtback.jpg);
        position: relative;
        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
    }
    .aBT p{
        position: relative;
    }
    .aBT h2{
        position: relative;
    }
    .aBT img {
        position: relative;
    }
    .aBT:before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #000000b8;
    }
    .aBT p {
        color: #fff;
        font-size: 15px;
        line-height: 23px;
    }
    
    .aBT h2 {
        color: var(--orange);
        margin-bottom: 20px;
    }
    section.top-sport {
        border-bottom: 2px solid #333;
        padding: 60px 0px;
        background: var(--dark-blue);
    }
    section.top-sport {
        border-bottom: 2px solid #333;
        border-top: 3px solid var(--orange);
    }
    .footer-content {
        text-align: center;
    }
    .list-menu ul {
        display: flex;
        list-style: none;
        margin-bottom: 0px;
        flex-direction: column;
        text-align: right;
    }
    .list-menu ul li a {
        color: #fff;
        text-decoration: none;
    }
    .footer-left h4 {
        color: #fff;
        font-family: poppins;
        font-weight: 600;
        font-size: 29px;
        letter-spacing: 1px;
    }
    ul.list11 li img {
        width: 40px;
    }
    ul.list11 li {
        margin-right: 20px;
        margin-top: 30px;
    }
    ul.list11 {
        padding-left: 0px;
        display: flex;
        margin-bottom: 0px;
        align-items: center;
    }
    .list-menu ul li {
        margin: 0px 10px;
    }
    section.footer {
        padding: 50px 0px 0px 0px;
      background-color: #0f2544;
    }
    .footer-content img {
        margin-bottom: 20px;
    }
    .list-menu {
        margin-top: 20px;
    }
    .copyright1 {
        padding: 20px 0px;
        border-top: 1px solid #fff;
        margin-top: 20px;
    }
    .copyright1 p {
        margin-bottom: 0px;
        text-align: center;
        color: #fff;
        font-size: 10px;
        font-family: 'Lato';
        letter-spacing: 1px;
    }
    .top-left ul li i {
        background: var(--dark-blue);
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        border-radius: 50%;
        color: #fff;
    }
    .top-left ul li {
        margin: 0px 3px;
    }
    .top-left ul {
        display: flex;
        margin-bottom: 0px;
        list-style: none;
    }
    section.top-sport h5 {
        text-align: center;
        color: var(--white);
        font-size: 26px;
    }
    ul.cappers-links {
        list-style: none;
    }
    ul.cappers-links li {
        font-size: 14px;
        text-align: center;
        display: inline-block;
        padding: 4px 8px;
    }
    ul.cappers-links li a {
    color: var(--white);
    }
    ul.cappers-links {
        list-style: none;
        text-align: center;
        margin-top: 30px;
    }
      /* Change background color of buttons on hover */
      .tab button:hover {
        background-color:var(--dark-blue);
        color:#fff;
      }
      
      /* Create an active/current "tab button" class */
      .tab button.active {
        background-color: #0f2544;
        color: #fff;
      }
      
      /* Style the tab content */
      .tabcontent {
        float: left;
        padding: 0px 12px;
        border: 1px solid #0f2544;
        width: 93%;
        border-left: none;
        height: auto;
        background: #0f2544;
        padding: 20px;
    }
    .lorem1 {
        overflow: hidden;
        clear: both;
    }
    button.odd {
        background: #fff;
        color: #000;
        padding: 11px 30px;
        border: none;
        border-radius: 4px;
        margin-bottom: 30px;
    }
    .select-box {
        text-align: right;
        
    }
    .select-box select {
        background: #fff;
        color: #000;
        padding: 11px 10px;
        border: none;
        border-radius: 4px;
        margin-bottom: 30px;
        width: 250px;
        font-weight: 600;
        letter-spacing: 1px;
    }
    table tr td {
        border: 1px solid #383636;
        padding: 10px;
    }
    .table11 h4 {
        color: #fff;
        margin-top: 20px;
        font-size: 18px;
    }
    .table11 h5 {
        color: #fff;
        font-size: 14px;
    }
    .table11 h5 i {
        color: #d8d816;
    }
    .tabcontent table tr td p {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
    }
    .right-top .slick-next {
        margin-right: 20px !important;
    }
    .tabcontent table tr td  {
        color: #fff;
        font-size: 14px;
    }
    .table11 p {
        color: #fff;
    }
    button.number-btn {
        background: #363739;
        border:none;
        color:#fff;
        padding: 5px 10px ;
    
    }

    button.buttonnew1 {
        background: #fc5400;
        border:none;
        color:#fff;
        padding: 5px 10px ;
    }
    .left-profile img {
        outline: 1px solid #d1d1d163;
        /* outline-offset: 3px; */
        width: 267px;
    }
    .profilemiddle {
        display: flex;
        justify-content: center;
    }
    .left-profile.profile33 {
        padding-left: 25px;
    }
    .tabcontent td{
        border: 0px;
    }
    .images-box {
        display: flex;
        align-items: center;
    }
    .images-part img {
        width: 55px;
        margin-right: 21px;}
    .content-part h4 { margin-bottom: 0px;    font-size: 20px; }   
    .table-blk p { margin-bottom: 0px;}
    .handicappers-links-section h5.handicappers-title, .links-wrapper h5 {
        color: #F48120;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .table23 {
    border-bottom: 1px solid rgb(71 69 69);
    padding: 10px 10px 30px 10px;
}
    div.logo-slide img {
        filter: brightness(0) invert(1);
    }
    .table11 {
        margin-top: 10px;
    }
    .sports-links li.sport-item {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 14px;
    }
    footer ul li a {
        color: #ddd;
        list-style: none;
        text-decoration: none;
    }
    footer ul li a:hover {
        color: var(--orange);
    }
    footer ul li {
        list-style: none;
    }
    .socials-wrapper ul li i {
        background: #ddd;
        width: 40px;
        height: 40px;
        background: var(--orange);
        line-height: 40px;
        text-align: center;
        font-size: 20px;
        color: #fff;
        border-radius: 50%;
    }
    .socials-wrapper ul li i:hover {
        background-color: var(--dark-blue);
       
    }
    
    .socials-wrapper ul li {
        display: inline-block;
        width: 67px;
    }
    p.disclaimer a {
        color: #ddd;
        text-decoration: none;
    }
    
    p.disclaimer {
        color: #ddd;
        margin-bottom: 0px;
    }
    .fixed-header {
        position: fixed !important;
        left: 0 !important;
        right: 0 !important;
        z-index: 9 !important;
        transition: all 500ms ease;
        top: 0;
        box-shadow: 0px 0px 2px 2px var(--dark-blue);
        padding: 7px 0px !important;
    }
    
    
    
    /*table css*/
    div.logo-slide img {
        width: 100px !important;
        object-fit: cover;
    }
    .left-top {
        display: flex;
       
    }
    .content-part p {
        color: #fff;
    }
    
    .content-part h4 {
        color: #fff;
    }
    .content-part:before {
        position: absolute;
        content: "";
        left: -11px;
        width: 1px;
        height: 48px;
        background: #ddd;
    }
    .content-part {
        position: relative;
    }
    div.logo-slide a {
        color: #fff;
        text-decoration: none;
        font-size: 20px;
    }
    .logo-slide span {
        width: 20px !important;
        color: var(--dark-blue) !important;
        font-size: 30px !important;
    }
    .logo-slide button.owl-prev {
        position: absolute !important;
        left: -40px !important;
        top: -15% !important;
    }
    .logo-slide button.owl-next {
        position: absolute;
        right: 0;
        top: -8px;
    }
    button.owl-prev {
        color: var(--dark-blue) !important;
        font-size: 30px !important;
    }
    button.owl-next {
        color: var(--dark-blue) !important;
        font-size: 30px !important;
    }
    .aBT ul li i {
        position: absolute;
        left: 0;
        top: 4px;
        color: var(--orange);
    }
    
    .aBT ul li {
        color: #fff;
        font-size: 14px;
        margin-top: 10px;
        position: relative;
        padding-left: 30px;
    }
    .aBT ul {
        list-style: none;
        padding-left: 0px;
    }
    .right-top img {
        width: 100px;
        object-fit: cover;
    }
    .right-top img {
        filter: brightness(0) invert(1);
    }
    .right-top a {
        color: #fff;
        font-size: 20px;
        text-decoration: none;
        margin-left: 20px;
    }
    .right-top button {
        margin-top: -5px;
    }
    .table-blk1 table tr td:nth-child(7) {
        display: none;
    }
    .table-blk1 table tr td:nth-child(6) {
        display: none;
    }
    .table-blk1 tr td {
        width: 20%;
     
    }
    .table-blk1 tr td button {
        width: 100px;
    }
    .rating {
        padding-top: 20px;
    }
    .left-profile {
        width:70px;
    }
    .progile-img {
        display: flex;
        justify-content: space-between;
        margin-top: 17px;
    }
    .progile-img11 {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }
    .progile-img13 {
        display: flex;
        justify-content: flex-start;
        margin-top: 40px;
        padding-left: 25px;
    }


  /* Add this CSS to your stylesheet */
.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
}

.popup .close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}

.popup .popup-content {
    margin: auto;
    display: block;
    max-width: 90%;
    max-height: 90%;
}

    .close:hover,
    .close:focus {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }

    .progile-img {
        display: flex;
        justify-content: space-between;
    }

   
    .progile-img11 {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }
    section.custumtab ul li button {
        background-color: #0f2544 !important;
        border-radius: 2px !important;
        padding: 12px 25px;
        color: #fff !important;
        border-radius: 8px !important;
        font-weight: 600;
    }
    section.custumtab ul.nav li {
        margin: 0px 17px 0px 0px;
    }
    
    section.custumtab ul.nav {
        border-bottom: 1px solid transparent;
    }
  

    .rating-up:hover {
        transition: 0.8s;
        box-shadow: 0px 20px 60px 0px rgb(98 98 98 / 16%);
        transform: translateY(-10px);
    }
    div#odds-api {
        margin-left: 120px;
    }
    div#odds-api {
        max-width: 1170px;
        margin: 0 30px 0 40px;
    }
    div#score select {
        margin-left: 40px;
        padding: 10px 10px;
        width: 130px;
    }
    a.Signup {
        margin-left: 8px;
        background: var(--dark-blue);
    }
    .signup-form {
        max-width: 550px;
        margin: 50px auto;
        background: #191919;
        padding: 20px;
        box-shadow: 0px 0px 11px 11px #000000d1;
    }
    .signup-form h2 {
        text-align: center;
        margin-bottom: 19px;
        color: #fff;
        font-size: 24px;
        text-transform: uppercase;
    }
    .signup-form input {
        width: 100%;
        background: transparent;
        margin-bottom: 20px;
        border: 1px solid #343333;
        padding: 8px 10px;
        color: #fff;
    }
    a.forget-name {
        background: transparent;
        padding: 0px 0px;
        color: #f00;
        text-decoration: underline;
    }
    .signup-form input[type="submit"] {
        background: var(--orange);
    }
    a.signname2 {
        background: transparent;
        padding: 0px 0px;
        color: #84955d;
        text-transform: uppercase;
        text-decoration: underline;
    }
    a.signname2.sign-regsiter {
        float: right;
    }

    ul.dropdown-menu {
        display: none !important;
    }
    .menu-center ul li:hover ul {
        display: block !important;
    }
    ul.dropdown-menu li {
        border-bottom: 1px solid #2e4566;
        padding: 10px 0px !important;
    }
    ul.dropdown-menu {
        background: #0f2544 !important;
        border-radius: 0px !important;
        padding: 0px !important;
    }
    .menu-center ul li ul li:hover {
        background: var(--orange);
    }
    .table-blk1 {
        margin-top: 8px;
    }
    .menu-center ul li ul li a {
        color: #fff !important;
        font-size: 14px !important;
    }
    .menu-center ul li ul li {
        padding: 12px 10px !important;
    }
    .main-banner img {
        width: 100%;
    }
    .banner-content h2 {
        font-size: 65px;
        font-weight: 800;
        color: #fff;
        letter-spacing: 2px;
    }
    
    .banner-content {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 530px;
        left: 29%;
    }
    .main-banner {
        position: relative;
    }
    .learn-btn a {
        background: var(--orange);
        padding: 15px 40px;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px;
        border-radius: 8px;
        letter-spacing: 1px;
        font-weight: 600;
    }
    .learn-btn {
        margin-top: 70px;
        margin-bottom: 16px;
    }
    .background-image {
        width: 100%;
        background-image: url('../../public/images/back11.jpg'); 
        background-size: cover; 
        background-position: 100%; 
        padding: 50px 0px;
      }
      .background-image.video-part {
        background: url('../../public/images/back22.jpg');
        background-size: 100%;
        padding-top: 100px;
        background-repeat: no-repeat
    }
    .background-image.video-part .heading-serving1 {
        margin-bottom: 100px;
    }
    .heading-serving1 h5 {
        color: #001adb;
        font-size: 15px;
        letter-spacing: 2px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .heading-serving1 h2 {
        font-weight: 800;
        font-size: 40px;
        color: #fc5400;
        font-family: 'Lato';
        letter-spacing: 2px;
    }
    .heading-serving1 {
        text-align: center;
        margin-bottom: 60px;
    }
    .box-part {
        text-align: center;
        background: #ffffff;
        padding: 70px;
        margin-top: 10px;
        box-shadow: 0px 0px 4px 0px #0d0c0c0a;
    }
    .box-part h4 {
        font-weight: 700;
        font-size: 20px;
        color: #fc5400;
        font-family: 'Lato';
        letter-spacing: 2px;
    }
    .box-part p {
        color: #0f2544;
        letter-spacing: 1px;
        margin-top: 20px;
    }
    .learn-btn.learnbtn1 {
        text-align: center;
    }
    .Meet h2 {
        font-weight: 700;
        font-size: 40px;
        color: #fff;
        font-family: 'Lato';
        letter-spacing: 2px;
        text-align: center;
        margin-bottom: 90px;
    }
    .profile-box {
        background: #ffffff;
        border: 4px solid #fc5400;
        padding: 20px 10px 10px 10px;
        padding-bottom: 40px;
    }
    .person-name1 {
        position: relative;
    }
    
    .person-name1 img {
        width: 100%;
        position: absolute;
        top: -89px;
        height: 300px;
        object-fit: contain;
    }
    .name-part img {
        width: 100%;
        max-width: 200px;
    }
    ul.lsit-part li i {
        color: #fc5400;
    }
    
    ul.lsit-part {
        margin-top: 30px;
        list-style: none;
        padding-left: 0px;
    }
    ul.lsit-part li {
        margin-bottom: 5px;
        color: #0f2544;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
    }
    .profile-content {
        padding-top: 30px;
        text-align: left;
        padding-left: 25px;
        padding-right: 25px;
    }
    .left-profile.profilewade img {
        width: 100%;
        height: 376px;
        object-fit: cover;
    }
    
    .left-profile.profilewade {
        width: 470px !important;
    }
    .profile-content p {
        font-size: 14px;
        letter-spacing: 1px;
        color: #0f2544;
        font-family: 'Lato';
    }
    .footerlogo {
        text-align: center;
    }
    
    .footerlogo img {
        width: 160px;
    }
    .list-menu li a {
        font-weight: 600;
        font-size: 18px;
        font-family: 'Lato';
        letter-spacing: 1px;
    }
    .list-menu ul li {
        margin-bottom: 20px;
    }
    .list-menu {
        margin-top: 20px;
    }
    .profile-part.wade-part {
        padding-left: 25px;
    }
    img.twitter1 {
        width: 30px !important;
    }