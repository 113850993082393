section.membership-part {
    background: url(../../public/images/memberback.jpg);
    padding: 30px 0px;
    background-size: cover;
}

.membership-heading {
    text-align: center;
    max-width: 950px;
    margin: 0 auto;
}

.membership-heading h2 {
    font-weight: 800;
    font-size: 40px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.membership-heading h5 {
    color: #001adb;
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;
}

.membership-heading p {
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 20px;
}

.membership-box input[type="text"]::placeholder {
    color: #a7a3a3;
    letter-spacing: 1px;
    font-family: 'Lato';
}

.menu-member {
    background: #102544;
    margin-top: 70px;
    padding-bottom: 70px;
}

.membership-box input[type="text"] {
    width: 100%;
    padding: 11px 10px;
    border-radius: 8px;
    border: 1.5px solid #fc5400;
    font-weight: 600;
}

.membership-box {
    max-width: 550px;
    margin: 50px auto 0 auto;
    position: relative;
}

.membership-box input[type="submit"] {
    position: absolute;
    right: 0;
    background: #fc5400;
    padding: 13px 40px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px 8px 8px 0px;
    letter-spacing: 1px;
    font-weight: 600;
    border: none;
}


.menu-part1 {
    padding-top: 100px;
    padding-left: 100px;
    padding-right: 100px;
    position: relative;
}

.main-part1 img {
    width: 200px;
}

.surviour-right h5 {
    color: #fc5400;
    letter-spacing: 1px;
    font-weight: bold;
    font-family: lato;
}

.surviour-right {
    margin-left: 70px;
}

.menu-part1 img {
    width: 200px;
}

.surviour-right h6 {
    text-align: right;
    color: #fc5400;
    font-style: italic;
    letter-spacing: 1px;
    font-family: lato;
    font-weight: 600;
}

.surviour-right i {
    padding-right: 6px;
}

.surviour-right h4 {
    text-align: right;
    color: #fc5400;
    letter-spacing: 1px;
    font-family: lato;
    font-weight: 600;
    font-size: 20px;
}

.left-bagel1 {
    color: #fff;
    display: flex;
    justify-content: space-between;

}

.left-bagel1 h3 {
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 600;
}

.surviour-list1 ul {
    padding-left: 17px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.surviour-list1 ul li {
    color: #fff;
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.Surviour-btn a {
    background: #fc5400;
    padding: 15px 40px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 8px;
    letter-spacing: 1px;
    font-weight: 600;
}

.menu-images {
    text-align: center;
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    left: 10%;
}

.surviour-list1 p {
    color: #fff;
    margin-bottom: -7px;
    letter-spacing: 1px;
    margin-top: 20px;
}

.dot-part {
    flex-grow: 1;
    border-bottom: 3px dotted #f9f9f9;
    margin: 13px 10px;
}


@media only screen and (max-width:768px) {
    .surviour-right {
        margin-left: 0px;
        margin-top: 25px;
    }

    .menu-part1 {
        padding-top: 50px;
        padding-left: 30px;
        padding-right: 30px;
        position: initial;
    }

    .menu-images {
        text-align: center;
        position: initial;
        top: 0;
        transform: translateY(0%);
        left: 0%;
    }

    .surviour-right h4 {
        text-align: left;
    }

    .surviour-right h6 {
        text-align: left;
    }

    .left-bagel1 {
        justify-content: space-between;
        flex-direction: column;
    }

    .menu-part1 img {
        width: 100%;
    }

    .membership-heading h2 {
        font-size: 24px;
    }

    .membership-box input[type="submit"] {
        position: relative;
        padding: 13px 40px;
        margin-top: 14px;
        font-size: 14px;
        border-radius: 8px 8px 8px 8px;

    }
}