:root {
    --white: #ffffff;
    --black: #000000;
    --orange: #FF8800;
    --dark-blue: #001adb;
    --lightblue: #e2f7ff;
    --black2:#101113;
    }
    
    body {
    font-family: "lato", sans-serif;
    overflow-x: hidden;
    background-color: #0a0b0c;
    }

    .covers-bootstrap5 .bg-white {
        background-color: #fff !important;
    }
 
    #covers-CoversScoreboard-league-next-and-prev a.navigation-anchor:not([aria-current=page]) {
        display: inline;
    }
    #covers-CoversScoreboard-league-next-and-prev {
        padding: 13px 16px;
        box-shadow: none;
    }
    div#covers-CoversScoreboard-league-next-and-prev {
        max-width: 600px;
    }
    #covers-CoversScoreboard-league-next-and-prev a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 22px;
        border-bottom: 2px solid transparent;
        color: var(--black);
        text-decoration: none;
    }
    .date {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
    }
    .dateRange {
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 1px;
    }
 .slide-div .slick-prev:before, .slick-next:before {
        color: #fc5400 !important;
    }
    .background-image .slick-prev:before, .slick-next:before {

        color: #fc5400 !important;
    }
    #covers-CoversScoreboard-league-next-and-prev a.navigation-anchor:hover {
        border-color: var(--orange);
    }
    #covers-CoversScoreboard-league-next-and-prev a.active {
        border-color: var(--orange);
    }
    div#odds-api > h2 {
        display: inline-block;
        padding: 15px 20px;
        letter-spacing: 1px;
        font-size: 17px !important;
    }
   div#score h2 {
    margin-top: 20px;
    color: #fff;
    font-size: 20px;
    margin-bottom: 20px;
    background: #0f2544;
}
.Kansas-city {
    background: #0f2544;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .1);
    margin-top: 10px;
}
    div#score .Kansas-city h2 {
        margin-top: 20px;
        color: #fff;
        font-size: 15px;
        margin-bottom: 20px;
        background: var(--orange);
        padding: 5px 16px;
       
    }
   .covers-CoversScoreboard-gameBox-teamAndOdds {
        display: flex;
        align-content: center;
        justify-content: space-between;
        gap: 16px;
    }
    .covers-CoversScoreboard-gameBox-Teams {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: flex-end;
    }
  .covers-CoversScoreboard-gameBox-Odds {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 4px;
    }
    .covers-box {
        color: var(--white);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        height: auto;
    }
.covers-CoversScoreboard-gameBox-teamShortName-Records {
        display: flex;
        flex-direction: column;
        gap: 4px;
        white-space: nowrap;
    }
    .covers-CoversScoreboard-gameBox-Team {
        display: flex;
        align-items: center;
        gap: 8px;
    }  

 .covers-CoversScoreboard-gameBox-TeamOdds {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;
    }

    .odds-header .covers-box {
        font-size: 12px;
        width: 82px;
        height: 40px;
        font-weight: 600;
    }
    .odds-data .covers-box {
        font-size: 14px;
        width: 82px;
        height: 40px;
        font-weight: 600;
        color: var(--white);
    }
.displayName {
        display: none;
    }
    
    .covers-CoversScoreboard-gameBox-teamShortName-Records a {
        text-decoration: none;
    }  
    span.shortName {
        color: var(--white);
        text-decoration: none;
        font-weight: 800;
    }
 .records {
        
    color: var(--white);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
    }
   .covers-CoversScoreboard-gameBox-Teams {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: flex-end;
    }
    .Kansas-box22 {
        padding: 0px 30px 30px 40px;
    }
    span.covers-badge.preGame-time {
        font-size: 12px;
        background: #ffe7d5;
        padding: 0px 3px;
        color: #000;
        font-weight: 600;
        padding: 5px 10px;
    }
    div#game {
        margin-top: 30px;
    }
    .covers-box span {
        color: var(--white);
        font-weight: 400;
    }
    
    section.tab-part ul.nav {
        border-bottom: 1px solid transparent;
    }
    section.tab-part ul li button {
        background-color: transparent !important;
        border-radius: 2px !important;
        padding: 12px 25px;
        color: #fff !important;
       
    }
    section.tab-part ul li button.active {
        background: var(--orange) !important;
        padding: 13px 30px;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px;
        border-radius: 8px;
        letter-spacing: 1px;
        font-weight: 600;
    }

    div#myTabContent {
        margin-top: 51px;
    }
    .score-box1 img {
        width: 14px;
    }
    section.tab-part ul.nav li {
        margin: 0px 17px 0px 0px;
    }


.score-box11{
    display: none;
}

#tabslider button.owl-prev {
    position: absolute !important;
    left: 2px !important;
    top: 6% !important;
}
#tabslider button.owl-next {
    position: absolute !important;
    right: 0 !important;
    top: 1px !important;
}
/* .tab-pane {
    opacity: 1 !important;
    visibility: visible !important;
  }  */
  div#odds-api {
    max-width: 1170px;
    margin: 0 30px 0 30px;
}
.slick-list{
    width:100%;
}
.dropdown-text{
    color: black !important;
}
ul.dropdown-menu {
    display: ruby-text !important;
}
.custom-dropdown {
    position: relative;
    display: inline-block;
}

.custom-dropdown .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.custom-dropdown:hover .dropdown-content {
    display: block;
}

.custom-dropdown .dropdown-content .dropdown-item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.custom-dropdown .dropdown-content .dropdown-item:hover {
    background-color: #f1f1f1;
}
/* .col-md-6 {
    width: 48%; 
    margin-bottom: 20px; 
} */

.matchup-details {
    /* Ensure the matchup details take up full width of the column */
    width: 100%;
    background-color: #333; /* Example background color */
    padding: 15px;
    border-radius: 5px;
}

.matchup-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* Optional: Align items vertically centered */
    align-items: center;
}


  
  
  